// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-new-links-archive-js": () => import("./../src/templates/newLinksArchive.js" /* webpackChunkName: "component---src-templates-new-links-archive-js" */),
  "component---src-templates-search-js": () => import("./../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */)
}

