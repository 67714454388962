module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"webdesignrepo","short_name":"wdr","start_url":"/","background_color":"#f2f5fc","theme_color":"#f2f5fc","display":"minimal-ui","icon":"src/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"bbf2233d50e9920728008c899502370c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-39874852-1","head":false,"pageTransitionDelay":0,"defer":false},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"wdr-blog","accessToken":"MC5YeXduOWhJQUFDSUFCNC04.77-977-9Ku-_vRzvv71V77-9IO-_ve-_ve-_vRl8WGnvv73vv71BXO-_vXUy77-977-9XmlvPO-_vU_vv70","schemas":{"blog_post":{"Main":{"uid":{"type":"UID","config":{"label":"UID"}},"title":{"type":"StructuredText","config":{"single":"heading1","label":"Title","placeholder":"Blog post title"}},"blurb":{"type":"StructuredText","config":{"multi":"paragraph","label":"Blurb"}},"author":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"Author"}},"publish_date":{"type":"Date","config":{"label":"Publish date"}},"sponsored":{"type":"Boolean","config":{"default_value":false,"label":"Sponsored"}},"body":{"type":"Slices","fieldset":"Slice zone","config":{"labels":{"text":[],"subheading":[],"subheading_h3":[],"image":[],"code":[],"footer":[]},"choices":{"text":{"type":"Slice","fieldset":"Text","description":"text","icon":"face","display":"list","non-repeat":{},"repeat":{"text":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item","label":"text"}}}},"subheading":{"type":"Slice","fieldset":"Subheading","description":"subheading","icon":"face","display":"list","non-repeat":{"heading":{"type":"StructuredText","config":{"single":"heading2, heading3, heading4, heading5, heading6","label":"Heading"}}},"repeat":{}},"image":{"type":"Slice","fieldset":"Image","description":"image","icon":"face","display":"list","non-repeat":{},"repeat":{"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}}}},"code":{"type":"Slice","fieldset":"Code","description":"code","icon":"face","display":"list","non-repeat":{},"repeat":{"code":{"type":"StructuredText","config":{"multi":"preformatted","label":"Code"}}}}}}}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
